import {AccessLevel, type AccessLevelState} from '$lib/classes/stores/access-level';
import type {ToastManager} from '$lib/classes/stores/toast-manager';
import type {UserWordsMapStore} from '$lib/classes/stores/get-user-words-map';
import type {Status} from '$lib/classes/data/user-word';
import type {SentenceId, WordId} from '$lib/classes/data/_primitives';
import {get} from 'svelte/store';

export class UserActionProcessor {

    constructor(
        private readonly accessLevel: AccessLevelState,
        private readonly toastManager: ToastManager,
        private readonly userWordsMapStore: UserWordsMapStore
    ) { }

    private get userNeedsToUpgrade() {
        return get(this.accessLevel) === AccessLevel.FreeTier
    }

    private promptToUpgrade() {
        this.toastManager.addUpgradeToast()
    }

    saveWord(id: WordId, status: Status | null) {
        if (this.userNeedsToUpgrade) {
            this.promptToUpgrade()
        } else {
            this.userWordsMapStore.statusChangeRequest(id, status)
        }
    }

    saveSentence(wordId: WordId, sentenceId: SentenceId) {
        if (this.userNeedsToUpgrade) {
            this.promptToUpgrade()
        } else {
            this.userWordsMapStore.sentenceChangeRequest(wordId, sentenceId)
        }
    }
}