import {derived, type Readable} from 'svelte/store';
import type {ProVersion} from '$lib/classes/stores/pro-version';
import type {Subscription} from '$lib/classes/stores/subscription';

export type IsPayingCustomer = Readable<boolean>

export function getIsPayingCustomer(subscription: Subscription, proVersion: ProVersion): IsPayingCustomer {
    return derived(([subscription, proVersion]), ([$subscription, $proVersion]) => {
        return ($proVersion && $proVersion.active && $proVersion.orderId !== null) || $subscription !== null
    })
}