import type {FirebaseApp} from 'firebase/app'
import type {Auth as FirebaseAuth} from 'firebase/auth'
import type {User} from 'firebase/auth'
import {FirebaseError} from 'firebase/app'
import type {Functions} from '$lib/classes/services/functions';
import {redirect} from '@sveltejs/kit';
import {browser} from '$app/environment';
import {replaceState} from '$app/navigation';
// noinspection ES6UnusedImports
import {
    connectAuthEmulator,
    verifyBeforeUpdateEmail,
    deleteUser,
    getAdditionalUserInfo,
    getAuth,
    getRedirectResult,
    GoogleAuthProvider,
    isSignInWithEmailLink,
    onAuthStateChanged,
    // sendSignInLinkToEmail,
    signInWithEmailLink,
    signInWithRedirect,
    signOut,
    // updateEmail,
    // connectAuthEmulator
} from 'firebase/auth'
import {differenceInDays} from 'date-fns';

// for emulating the authentication locally start emulation server with firebase emulators:start --only auth
// warning 'WARNING: You are using the Auth Emulator, which is intended for local testing only. Do not use with production credentials.'
// >> 'it means don't type in your real password or anything else sensitive since it's going to be exposed over http when traveling to the emulator.'
// WENN AUTH EMULIERT MUSS AUCH FIRESTORE EMULIERT WERDEN - wegen Rules !!! - Rules? Auth link funktioniert auf jeden Fall nicht...?! Problem in function code?

export class Auth {
    private readonly auth: FirebaseAuth

    // public user: Writable<User | null> = writable(null)

    constructor(
        firebaseApp: FirebaseApp,
        private readonly functions: Functions,
        private readonly onNewUser: (user: User) => Promise<void>,
        private readonly onLogin: (user: User) => Promise<void>,
        private readonly onLogout: () => Promise<void>,
    ) {
        this.auth = getAuth(firebaseApp)

        // To apply the default browser preference instead of explicitly setting it.
        // stand bei Google login - beeinflusst auch Authentication Mail Sprache?
        this.auth.useDeviceLanguage()

        // if (location.hostname === 'localhost') {
        //     connectAuthEmulator(auth, "http://localhost:9099")
        // }
    }

    async init(): Promise<void> {

        if (!browser) return

        const signInWithEmail = isSignInWithEmailLink(this.auth, window.location.href)
        if (signInWithEmail) {
            // TODO move email reading and clearing localStorage entry to here
            if (this.auth.currentUser) {
                // if already logged in just clean url
                replaceState(window.location.pathname, {})
            } else {
                // try signing in user
                try {
                    // console.log('signInWithEmailLink >>>')
                    let email = window.localStorage.getItem('emailForSignIn');
                    if (!email) {
                        // User opened the link on a different device. To prevent session fixation
                        // attacks, ask the user to provide the associated email again. For example:
                        email = window.prompt('Please provide your email for confirmation');
                    }
                    // 'if' for compiler
                    if (email) {
                        let credential = await signInWithEmailLink(this.auth, email, window.location.href)
                        const details = getAdditionalUserInfo(credential)
                        if (details?.isNewUser) {
                            console.log('+++ NEW USER +++')
                            await this.onNewUser(credential.user)
                        }
                        window.localStorage.removeItem('emailForSignIn');
                        replaceState(window.location.pathname, {})
                    }
                } catch (error: unknown) {
                    window.localStorage.removeItem('emailForSignIn');
                    if (error instanceof FirebaseError) {
                        if (error.code === 'auth/invalid-action-code') {
                            alert('The login link you used is no longer valid, please request a new one.')
                            redirect(307, `/signin`);
                        } else {
                            alert(`There was an error while signing in.\nError code: ${error.code}.`)
                            const credential = GoogleAuthProvider.credentialFromError(error);
                            throw new Error('ERROR auth signInWithEmail - credential ' + credential, {cause: error})
                        }
                    }
                }
            }
        }

        const redirectResult = await getRedirectResult(this.auth)
        if (redirectResult) {
            try {
                // This gives you a Google Access Token. You can use it to access Google APIs.
                // const OAuthCredential = GoogleAuthProvider.credentialFromResult(redirectResult);
                // const token = OAuthCredential.accessToken;

                let credential = redirectResult
                const details = getAdditionalUserInfo(credential)
                if (details?.isNewUser) {
                    console.log('+++ NEW USER +++')
                    await this.onNewUser(credential.user)
                }
            } catch (error: unknown) {
                if (error instanceof FirebaseError) {
                    const credential = GoogleAuthProvider.credentialFromError(error);
                    throw new Error('ERROR auth redirectResult - credential ' + credential, {cause: error})
                }
            }
        }

        return new Promise((resolve, reject): void => {
            onAuthStateChanged(this.auth, async user => {
                console.log('onAuthStateChanged >>>')
                try {
                    if (user) {
                        console.log('USER', user.uid)
                        await this.onLogin(user)
                    } else {
                        await this.onLogout()
                        console.log('logged OUT')
                    }
                    resolve()
                } catch (error) {
                    reject(error)
                }
            })
        })
    }

    async logout() {
        await signOut(this.auth)
    }

    // async signup(email, password) {
    //     let credential = await createUserWithEmailAndPassword(this.auth, email, password)
    //     console.log('signed up', credential);
    //     await USER.createUser()
    // },
    //
    // async login(email, password) {
    //     let credential = await signInWithEmailAndPassword(this.auth, email, password)
    //     console.log('logged in', credential);
    // },

    async signInWithEmail(email: string, url: string) {
        await this.functions.emailAuthLink({email, url})
        // TODO add trycatch
        window.localStorage.setItem('emailForSignIn', email);
    }

    async signInWithGoogle(url: string) {
        const provider = new GoogleAuthProvider();
        // prevent skipping the account selection step on subsequent
        // logins when only one google account is registered
        provider.setCustomParameters({
            prompt: 'select_account'
        });

        try {
            // google will redirect to current url when calling signIn function
            // so if there's a saved url in history state, set it before calling signin
            replaceState(url, {})
            await signInWithRedirect(this.auth, provider)
        } catch (error) {
            console.error('Error while signing in with redirect', error)
        }
    }

    async changeEmail(user: User, newEmail: string) {
        const actionCodeSettings = {
            url: window.location.origin + '/signin?email=' + encodeURIComponent(newEmail),
        }
        await verifyBeforeUpdateEmail(user, newEmail, actionCodeSettings)
        // TODO send email that notifies of requested change with uid and current email --> check if changed and update in mail service
    }

    async deleteAccount() {
        const currentUser = this.auth.currentUser
        if (!currentUser) return
        await deleteUser(this.auth.currentUser)
    }

    getCurrentUser() {
        return this.auth.currentUser
    }

    getUserEmail() {
        return this.auth.currentUser?.email ?? undefined
    }

    getUid() {
        return this.auth.currentUser?.uid
    }

    getDaysSignedUp(): number | null {
        const currentUser = this.getCurrentUser()
        if (!currentUser) return null
        const creationTime = currentUser.metadata.creationTime
        if (!creationTime) return null
        return differenceInDays(new Date(), new Date(creationTime))
    }
}

