import {get, type Writable, writable} from 'svelte/store';

export enum ToastType {
    Info = 'INFO',
    Success = 'SUCCESS',
    Error = 'ERROR'
}

export type Toast = {
    message: string,
    type: ToastType,
    autoRemove: boolean,
    link?: {
        text: string,
        url: string
    }
}

const upgradeToast = {
    message: `<div>Go <b>Pro</b> to save & delete words</div>`,
    type: ToastType.Info,
    autoRemove: true,
    link: {
        text: '» Upgrade',
        url: '/pricing'
    }
}

const errorLoadingPricesToast = {
    message: '<p><b>Oops! We\'re having trouble loading the prices.</b> Our team has been notified, and we\'re on it! Please try refreshing the page or check back soon.</p><p>If the issue persists, feel free to reach out to us at <u>support@vocabeo.com</u> - we\'re happy to help!</p>',
    type: ToastType.Error,
    autoRemove: false,
}

// noinspection JSUnusedLocalSymbols
const exampleToasts = [
    {
        message: 'This is an info toast',
        type: ToastType.Info,
        autoRemove: true,
    },
    {
        message: 'This is a success toast',
        type: ToastType.Success,
        autoRemove: true,
    },
    {
        message: 'This is an error toast',
        type: ToastType.Error,
        autoRemove: false,
    }
]

export class ToastManager {
    private readonly toasts: Writable<Toast[]> = writable([
        // ...exampleToasts
    ])
    public readonly subscribe = this.toasts.subscribe

    constructor() { }

    private addToast(toast: Toast) {
        this.toasts.update(toasts => [...toasts, toast])
    }

    addUpgradeToast() {
        const toasts = get(this.toasts)
        if (toasts.includes(upgradeToast)) return
        this.addToast(upgradeToast)
    }

    addErrorLoadingPricesToast() {
        this.addToast(errorLoadingPricesToast)
    }

    removeToast(toast: Toast) {
        this.toasts.update(toasts => toasts.filter(t => t !== toast))
    }
}