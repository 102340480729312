<!--suppress UnnecessaryLabelJS -->
<script lang="ts">
    import '$lib/styles/styles.css';
    import NavLinks from '$lib/components/_page/NavLinks.svelte';
    import Filter from '$lib/components/browse/Filter.svelte';
    import LogoLoginSettings from '$lib/components/_page/LogoLoginSettings.svelte';
    import FilterMobile from '$lib/components/browse/FilterMobile.svelte';
    import {hiddenFn} from '$lib/utils/hiddenFn.js';
    import {beforeNavigate, goto} from '$app/navigation';
    import {page, updated} from '$app/state';
    import RemoteChanges from '$lib/components/_page/RemoteChanges.svelte';
    import {logInProduction} from '$lib/utils/logInProduction.js';
    import {browser, version} from '$app/environment';
    import {setContext} from 'svelte';
    import {keyUserActionProcessor, keyAuth, keyDevice, keyFunctions, keyToastManager} from '$lib/context-keys';
    import {DeviceType} from '$lib/stores/ui/device';
    import Toasts from '$lib/components/_page/toasts.svelte';
    import PopupModal from '$lib/components/_page/popup-modal.svelte';
    import NextFeature from '$lib/components/next-feature/next-feature.svelte';

    export let data
    const {
        setup,
        database,
        filterOptions,
        userWordsMap,
        displayWords,
        filterSettings,
        filterSettingsFiltered,
        userSettings,
        loggedIn,
        remoteChanges,
        device,
        pageWidth,
        functions,
        auth,
        toastManager,
        userActionProcessor,
        isPayingCustomer,
    } = data

    setContext(keyDevice, device)
    setContext(keyFunctions, functions)
    setContext(keyAuth, auth)
    setContext(keyToastManager, toastManager)
    setContext(keyUserActionProcessor, userActionProcessor)

    let setupFinished = false
    setup.then((_) => {
        setupFinished = true
    })

    $: if (setupFinished) {
        const pathname = page.url.pathname
        if ($loggedIn) {
            switch (pathname) {
                case '/signin':
                    goto('/')
            }
        } else {
            // PROTECT ROUTES
            switch (pathname) {
                case '/settings':
                case '/stats':
                case '/subscription':
                case '/testimonial':
                case '/next-feature':
                    console.log('PROTECT ROUTE >> redirect')
                    goto('/signin', {state: {url: `${page.url.href}`}})
            }
        }
    }

    function handleKeydown(event: KeyboardEvent) {
        if (event.code === 'KeyH' && event.altKey && event.metaKey) hiddenFn()
    }

    beforeNavigate(async navigation => {
        await updated.check()
        // NOTE $updated doesn't seem to be checked in dev mode !!!
        // NOTE $updated needs to be used with $ syntax so there's a subscriber to the store (what if no subscriber..?!)
        // NOTE navigation.to might be null - I think that's the case after window.location.href is set and callback runs once more
        if (updated.current) {
            logInProduction(`$updated >>> navigation\n ${JSON.stringify(navigation, null, 2)}`)
        }
        if (updated.current && navigation.to) {
            logInProduction(`beforeNavigate - ${version} - $updated && navigation.to `)
            const navigatingTo = navigation.to.url.href
            navigation.cancel()
            logInProduction('navigating to ' + navigatingTo)
            window.location.href = navigatingTo
        }
    })

    async function checkAndHandleVersionUpdate() {
        await updated.check()
        if (updated.current) {
            logInProduction(`window on:focus - version ${version} - updated! >> reload`)
            window.location.reload();
        }
    }

    const dev = import.meta.env.DEV
    const prod = import.meta.env.PROD
</script>

<svelte:head>
    {#if dev}
        <!-- prevent staging from being indexed by (google) bot -->
        <meta name="robots" content="noindex">
    {/if}

    {#if browser}
        <!-- NOTE Tawk yOffset stopped working with initialization in hooks.client.ts -->
        {@const allScriptNotDisabled = localStorage.getItem('disableScripts') === null}
        {@const tawkNotDisabled = localStorage.getItem('disableTawk') === null}
        {@const tawkEnabled = localStorage.getItem('enableTawk') !== null}
        {#if tawkEnabled || (prod && allScriptNotDisabled && tawkNotDisabled)}
            <script src="/tawk.js"></script>
        {/if}
    {/if}
</svelte:head>

<svelte:window
        on:keydown={handleKeydown}
        on:focus={checkAndHandleVersionUpdate}
/>

{#if $remoteChanges}

    <RemoteChanges/>

{:else}

    <Toasts toastManager={toastManager}/>


    {#await setup then _}
        {#if $loggedIn && (auth.getDaysSignedUp() ?? 0) >= 7}
            <PopupModal
                    localStorageKey="survey-one"
                    maxWidth={500}
                    background="var(--color-light)"
                    let:closeDialog
            >
                <NextFeature
                        auth={auth}
                        database={database}
                        isPayingCustomer={$isPayingCustomer}
                        skippeable={true}
                        on:skip={closeDialog}
                        closeable={true}
                        on:close={closeDialog}
                />
            </PopupModal>
        {/if}
    {/await}

    <div id="page"
         bind:clientWidth={$pageWidth}
         style="--selected-color:{$userSettings.selectedColor};
            --color-maskulinum:{$userSettings.nounColors.maskulinum};
            --color-femininum:{$userSettings.nounColors.femininum};
            --color-neutrum:{$userSettings.nounColors.neutrum};"
    >
        <LogoLoginSettings
                setup={setup}
                loggedIn={loggedIn}
        />

        <NavLinks
                loggedIn={loggedIn}
        />

        {#await setup then _}
            {#if page.url.pathname.startsWith('/browse') && $device === DeviceType.Desktop}
                <div style="grid-area: filter;">
                    <Filter
                            filterOptions={filterOptions}
                            userWordsMap={userWordsMap}
                            filterSettings={filterSettings}
                            filterSettingsFiltered={filterSettingsFiltered}
                            displayWords={displayWords}
                    />
                </div>
            {/if}
        {/await}

        <main>

            {#await setup then _}
                {#if page.url.pathname.startsWith('/browse') && $device === DeviceType.Mobile}
                    <FilterMobile
                            filterOptions={filterOptions}
                            userWordsMap={userWordsMap}
                            filterSettings={filterSettings}
                            filterSettingsFiltered={filterSettingsFiltered}
                            displayWords={displayWords}
                    />
                {/if}
            {/await}

            <slot/>
        </main>
    </div>

{/if}

<style>
    #page {
        position: relative;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 1.2rem 230px 1.2rem 1fr;
        grid-template-rows: min-content min-content 1fr;
        grid-template-areas:
                ". logo   . main"
                ". nav    . main"
                ". filter . main";
    }

    main {
        position: relative;
        height: 100%;
        grid-area: main;
        display: flex;
        flex-direction: column;
    }

    @media (max-width: 1000px) {
        #page {
            grid-template-columns: 100%;
            --logo-height: 3.3rem;
            --nav-height: 3.3rem;
            grid-template-rows: var(--logo-height) calc(100% - var(--logo-height) - var(--nav-height)) var(--nav-height);
            grid-template-areas:
                    "logo"
                    "main"
                    "nav";
        }
    }

</style>