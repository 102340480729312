import {derived, type Readable} from 'svelte/store';
import type {WordId} from '$lib/classes/data/_primitives';
import {Status, UserWord} from '$lib/classes/data/user-word';
import type {Word} from '$lib/classes/data/word';

export type UserWordsToLearn = Readable<UserWord[]>

//NOTE keep constructor function because of derived
export function getUserWordsToLearn(userWordsMap: Readable<Record<WordId, UserWord>>, allWords: Word[]): Readable<UserWord[]> {
    return derived(userWordsMap, (value, set) => {

        set(calculateUserWordsToLearn(value, allWords))

        const interval = setInterval(() => {
            set(calculateUserWordsToLearn(value, allWords))
        }, 60000)

        return () => clearInterval(interval)
    })
}

function calculateUserWordsToLearn(userWordsMap: Record<WordId, UserWord>, allWords: Word[]): UserWord[] {
    // NOTE based on allWords order to keep order of words in list
    const now = Date.now()
    const userWordsDueToReview = allWords.reduce((acc, word) => {
        const userWord = userWordsMap[word.id]
        if (!userWord) return acc
        const savedToLearn = userWord.status === Status.Learn
        const dueToReview = userWord.toLearnNext <= now
        if (savedToLearn && dueToReview) {
            return [...acc, userWord]
        } else {
            return acc
        }
    }, [] as UserWord[])
    return userWordsDueToReview.sort((uW1, uW2) => uW1.toLearnNext - uW2.toLearnNext)
}