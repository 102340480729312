import {initializePaddle, type Paddle, type PaddleEventData, type Variant} from '@paddle/paddle-js';
import {getIpAddress} from '$lib/helper-fns/getIpAddress.js';
import type {SubscriptionDoc} from '$lib/classes/stores/subscription';
import type {Tier} from '$lib/classes/_types/tier';

// TODO
// QUESTION would it be clearer to open/close paymentService with login/logout and pass uid/email there..?!?
//  argument against: paymentService is only needed for pricing/subscription route

export class PaymentService {
    private PaddleInstance: Paddle | null | undefined = null

    constructor() {}

    private async getPaddleInstance() {
        // NOTE saving promise to this.PaddleInstance would prevent calling initialize too often?
        //  (no problem in the sequential layout setup but in case of a more parallel scenario)
        if (this.PaddleInstance === null) {
            try {
                this.PaddleInstance = await initializePaddle({
                    environment: import.meta.env.DEV ? 'sandbox' : 'production',
                    token: import.meta.env.VITE_PADDLE_CLIENT_SIDE_TOKEN,
                    // environment: 'production',
                    // token: 'live_8d0884dc8928d9e7fc4ec14eb1a',
                    pwCustomer: {},
                    checkout: {
                        settings: {
                            displayMode: 'overlay',
                            // frameTarget: PADDLE_CHECKOUT_CONTAINER_CLASS,
                            // frameInitialHeight: 500,
                            // frameStyle: 'width: 100%; min-width: 312px; background-color: transparent; border: none;',
                            showAddDiscounts: true,
                            allowLogout: false,
                        }
                    },
                    // eventCallback: function (data) {
                    //     switch (data.name) {
                    //         case "checkout.closed":
                    //             break;
                    //         default:
                    //             console.log('Paddle eventCallback', data.name)
                    //     }
                    // }
                })
            } catch (error: unknown) {
                if (error instanceof Error) {
                    throw new Error('ERROR initializing Paddle', {cause: error})
                }
            }
        }
        if (this.PaddleInstance === undefined) {
            throw new Error('ERROR initializing Paddle - #PaddleInstancePromise is undefined')
            // Should only happen server-side when !window
        }
        return this.PaddleInstance
    }

    async init() {
        await this.getPaddleInstance()
    }

    async authenticate(subscription: SubscriptionDoc | null) {
        // question - common to call with null or better 'block' that outside and expect a subscription?
        //  better return if s === null before getting paddle instance? (.init() is called from setup anyway)
        const paddleInstance = await this.getPaddleInstance()
        if (!paddleInstance) return

        if (subscription === null) return

        try {
            const customerId = subscription.customerId
            if (customerId) {
                paddleInstance.Update({
                    pwCustomer: {
                        id: customerId
                    }
                })
            } else {
                // noinspection ExceptionCaughtLocallyJS
                throw new Error('customerId missing in subscription')
            }
        } catch (error) {
            console.error('ERROR paymentService.authenticate', error)
        }
    }

    async revokeAuthorization() {
        const paddleInstance = await this.getPaddleInstance()
        if (!paddleInstance) return

        paddleInstance.Update({
            pwCustomer: {
                id: undefined
            }
        })
    }

    async getPrices(tiers: Tier[]): Promise<Tier[]> {
        const paddleInstance = await this.getPaddleInstance()
        if (!paddleInstance) return []

        const ipAddress = await getIpAddress()
        const request = {
            customerIpAddress: ipAddress,
            items: tiers,
        }

        const result = await paddleInstance.PricePreview(request)
        const lineItems = result.data.details.lineItems

        const mainTierPrice = Number(lineItems[0].unitTotals.total)

        return tiers.map((tier, index): Tier => {
            const tierPrice = Number(lineItems[index].unitTotals.total)
            const percentageSaved = calculatePercentageSaved(mainTierPrice, tierPrice)
            return {
                ...tier,
                price: lineItems[index].formattedUnitTotals.total,
                lessPercentThanInitialPrice: percentageSaved,
            }
        })
    }

    async updateCheckout(eventCallback: (eventData: PaddleEventData) => void) {
        const paddleInstance = await this.getPaddleInstance()
        if (!paddleInstance) return

        paddleInstance.Update({
            eventCallback: eventCallback
        })
    }

    async openCheckout(options: {
        email: string | undefined,
        uid: string | undefined,
        displayMode?: 'inline' | 'overlay',
        transactionId?: string
    }) {
        const uid = options.uid
        const email = options.email
        if (uid === undefined || email === undefined) {
            console.error('ERROR openCheckout - uid or vocabeoEmail is undefined')
            return
        }
        const paddleInstance = await this.getPaddleInstance()
        if (!paddleInstance) return

        const variant: Variant = options.transactionId ? 'multi-page' : 'one-page'

        const baseOptions = {
            settings: {
                variant: variant,
                displayMode: options.displayMode,
            },
            customer: {
                email: email
            },
            customData: {
                uid: uid,
                vocabeoEmail: email,
            },
        }

        if (options.transactionId) {
            paddleInstance.Checkout.open({
                ...baseOptions,
                transactionId: options.transactionId,
            })
        } else {
            paddleInstance.Checkout.open({
                ...baseOptions,
                items: [{
                    quantity: 1,
                    priceId: import.meta.env.VITE_PADDLE_PRO_BRONZE_PRICE_ID,
                }],
            })
        }
    }
}

function calculatePercentageSaved(mainPrice: number, discountedPrice: number) {
    return Math.round((1 - discountedPrice / mainPrice) * 100)
}