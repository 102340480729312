import {get, type Writable, writable} from 'svelte/store'
import {goto} from '$app/navigation';
import type {Database} from '$lib/classes/services/database';
import type {Functions} from '$lib/classes/services/functions';
import {timer} from '$lib/utils/timer';
import type {
    SubscriptionNotification,
    SubscriptionScheduledChangeNotification
} from '@paddle/paddle-node-sdk';
import type {ManageSubscriptionLinks} from '$lib/classes/_types/manage-subscription-links';

type SubscriptionItem = {
    priceId: string
    priceName: string
    quantity: number
    unitPrice: { amount: string, currencyCode: 'EUR' | 'USD' | 'GBP' }
    productId: string
}

// TODO save subscriptionDoc as it comes from paddle to database and
//  add converter class that transforms to the necessary frontend format
export type SubscriptionDoc = {
    id: SubscriptionNotification['id']
    customerId: SubscriptionNotification['customerId']
    status: SubscriptionNotification['status']
    items: SubscriptionItem[]
    previouslyPrepaidMonths: number
    monthsSubscribed: number,
    firstBilledAt: SubscriptionNotification['firstBilledAt']
    nextBilledAt: SubscriptionNotification['nextBilledAt']
    scheduledChange: SubscriptionScheduledChangeNotification | null
}

export class Subscription {

    private readonly store: Writable<SubscriptionDoc | null> = writable(null)
    subscribe = this.store.subscribe

    private cancelDocSubscription = () => {}

    // NOTE setting promise prevents multiple calls to getPortalSession while loading
    private manageSubscriptionLinksPromise: Promise<ManageSubscriptionLinks> | null = null

    constructor(
        private readonly database: Database,
        private readonly functions: Functions
    ) {}

    async connect(uid: string): Promise<void> {
        const timer_subscription = timer('subscription.init')

        return new Promise(async (resolve, reject) => {
            try {
                this.cancelDocSubscription = await this.database.subscribeSubscriptionDoc(uid, doc => {
                    if (doc) {
                        if (doc.items.length !== 1) {
                            console.error(`ERROR incorrect subscription item count ${doc.items.length} subscriptionId ${doc.id}`)
                        }
                        this.store.set(doc)
                    } else {
                        // NOTE necessary for when subscription is canceled
                        this.store.set(null)
                    }
                    timer_subscription()
                    resolve()
                })
            } catch (error) {
                reject(new Error('ERROR subscription.connect()', {cause: error}))
            }
        })
    }

    disconnect() {
        this.cancelDocSubscription()
        this.store.set(null)
    }

    async cancel(subscriptionId: string) {
        try {
            await this.functions.cancelSubscription(subscriptionId)
        } catch (error) {
            throw new Error('ERROR cancel subscription', {cause: error})
        }
    }

    async reactivate(subscriptionId: string) {
        try {
            await this.functions.reactivateSubscription(subscriptionId)
        } catch (error) {
            throw new Error('ERROR reactivate subscription', {cause: error})
        }
    }

    async updatePaymentMethod(subscriptionId: string) {
        try {
            const payload = await this.functions.getPaymentMethodUpdateTransaction(subscriptionId)
            const transaction = payload.data
            const transactionId = transaction.id
            await goto(`/subscription?_ptxn=${transactionId}`)
        } catch (error) {
            throw new Error('ERROR updating payment Method', {cause: error})
        }
    }

    async getManageSubscriptionLinks(): Promise<ManageSubscriptionLinks> {
        const subscription = get(this.store)
        if (subscription === null) {
            //QUESTION common to throw error if method was called when it shouldn't (without active subscription)?
            throw new Error('ERROR subscription.getManageSubscriptionLinks() - subscription === null')
        }
        if (this.manageSubscriptionLinksPromise === null) {
            const customerId = subscription.customerId
            const subscriptionId = subscription.id
            this.manageSubscriptionLinksPromise = this.functions.getPortalSession(customerId, subscriptionId)
        }
        try {
            return await this.manageSubscriptionLinksPromise
        } catch (error) {
            throw new Error('ERROR getting portal session', {cause: error})
        }
    }

    getPlanName(subscription: SubscriptionDoc | null) {
        // NOTE subscription as parameter to make function call reactive
        if (subscription === null) {
            console.error('ERROR subscription.getPlan() - subscription === null')
            return
        }
        const monthsTotal = subscription.previouslyPrepaidMonths + subscription.monthsSubscribed
        return monthsTotal > 12 ? 'Platinum'
            : monthsTotal > 6 ? 'Gold'
                : monthsTotal > 3 ? 'Silver'
                    : 'Bronze'
    }
}