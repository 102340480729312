import {derived, type Readable} from 'svelte/store';
import type {ProVersion} from '$lib/classes/stores/pro-version';
import type {Subscription} from '$lib/classes/stores/subscription';
import type {LoggedIn} from '$lib/classes/stores/logged-in';

export enum AccessLevel {
    Public = 'PUBLIC',
    Trial = 'TRIAL',
    FreeTier = 'FREE_TIER',
    ProPlan = 'PRO_PLAN',
}

export type AccessLevelState = Readable<AccessLevel>

export function getAccessLevel(loggedIn: LoggedIn, proVersion: ProVersion, subscription: Subscription): AccessLevelState {
    return derived([loggedIn, proVersion, subscription], ([$loggedIn, $proVersion, $subscription]) => {
        if ($loggedIn) {
            const hasActiveProVersion = $proVersion && $proVersion.active
            const hasPaidProVersion = $proVersion && $proVersion.orderId !== null
            const hasActiveSubscription = $subscription !== null
            if (hasActiveProVersion && !hasPaidProVersion) {
                return AccessLevel.Trial
            } else if (hasActiveProVersion && hasPaidProVersion) {
                return AccessLevel.ProPlan
            } else if (hasActiveSubscription) {
                return AccessLevel.ProPlan
            } else {
                return AccessLevel.FreeTier
            }
        } else {
            return AccessLevel.Public
        }
    })
}