<!--suppress CssUnresolvedCustomProperty -->
<script lang="ts">
    import {goto} from '$app/navigation';
    import {fade} from 'svelte/transition'
    import SvgIcon from '$lib/components/assets/SvgIcon.svelte';
    // noinspection ES6UnusedImports
    import {page} from '$app/state';
    import Logo from '$lib/components/assets/Logo.svelte';
    import LoadingDots from '$lib/components/assets/LoadingDots.svelte';
    import type {Readable} from 'svelte/store';

    export let setup: Promise<void>
    export let loggedIn: Readable<boolean>
</script>

<nav>
    <ul>
        <li>
            <a href="/">
                <Logo/>
            </a>
        </li>

        {#await setup}
            <li>
                <LoadingDots width="40"/>
            </li>
        {:then _}
            {#if !$loggedIn}
                <li id="nav-warning" transition:fade|global>
                    <a href="/signin"
                       on:click|preventDefault={()=> goto('/signin', {state: {url: `${page.url.href}`}}) }>
                        Test mode &thinsp;»
                    </a>
                </li>
            {/if}
            <li id="nav-link-signin" transition:fade|global>
                {#if $loggedIn}
                    <a
                            href="/settings"
                            aria-label="Go to your Settings"
                            data-testid="nav-link-settings"
                    >
                        <SvgIcon name="settings" width="20" height="20"
                                 fill="var(--color-main)"
                                 style={"cursor: pointer;"}
                        />
                    </a>
                {:else}
                    <a href="/signin"
                       on:click|preventDefault={()=> goto('/signin', {state: {url: `${page.url.href}`}}) }>
                        | Sign in
                    </a>
                {/if}
            </li>
        {/await}

    </ul>
</nav>


<style>

    nav {
        grid-area: logo;
    }

    ul {
        list-style-type: none;
        margin-block-start: 0;
        margin-block-end: 0;
        padding-inline-start: 0;

        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 1.2rem 0 .8rem 0;
        /*border: 1px solid purple;*/
    }

    #nav-link-signin {
        padding: 0 .2rem;
        margin-bottom: .1rem;
        font-size: .95rem;
        /*outline: 1px solid purple;*/
    }

    #nav-link-signin :global(svg) {
        display: block;
    }

    li {
        user-select: none;
        -webkit-user-select: none;
        border-radius: var(--border-radius);
    }

    a {
        display: flex;
        color: inherit;
        text-decoration: none;
        font-weight: var(--font-emph);
    }

    #nav-warning {
        display: none;
        margin-left: auto;
        margin-right: .2rem;
        padding: .2rem .6rem;
        font-weight: bold;
        color: #996402;
        background: #faf1de;
    }

    @media (prefers-color-scheme: dark) {

        #nav-warning {
            background: #785719;
            color: #bfa575;
        }

    }

    @media (max-width: 1000px) {

        ul {
            padding: 0 .5rem .4rem .5rem;
            height: 100%;
            font-size: 14px;
            border-bottom: 1px solid var(--color-dark);
        }

        #nav-link-signin {
            margin-bottom: .15rem;
        }

        #nav-warning {
            display: block;
        }

    }
</style>