<script lang="ts">
    import {onMount} from 'svelte';
    import IconClose from '$lib/components/assets/IconClose.svelte';
    import {clickOutside} from '$lib/utils/click-outside';

    export let localStorageKey: string
    export let maxWidth = 700
    export let background = 'var(--bg)'

    let dialog: HTMLDialogElement

    let renderDialog = true

    const hasSeen = localStorage.getItem(localStorageKey)

    if (import.meta.env.DEV || hasSeen) renderDialog = false  // NOTE exclude DEV because of playwright tests

    onMount(() => {
        if (renderDialog) {
            dialog.showModal()
        }
    })

    function closeDialog() {
        dialog.close()
        localStorage.setItem(localStorageKey, 'seen')
        renderDialog = false
    }
</script>

{#if renderDialog}
    <dialog
            bind:this={dialog}
            style:width="min({maxWidth}px, 90%)"
            style:background
            on:close={closeDialog}
    >
        <div
                use:clickOutside
                on:outclick={closeDialog}
        >

            <IconClose width="25" height="25"
                       fill="var(--color-Verb)"
                       on:click={closeDialog}
            />
            <slot closeDialog={closeDialog}/>
        </div>
    </dialog>
{/if}

<style>
    dialog {
        max-height: 80%;
        padding: 3rem 2.6rem 2.2rem 2.6rem;
        border: none;
        border-radius: var(--border-radius);
        color: inherit;
    }

    dialog:active,
    dialog:focus {
        outline-color: var(--color-Verb);
    }

    dialog > div {
        display: flex;
        flex-direction: column;
        /*align-items: center;*/
    }

    dialog::backdrop {
        background: rgba(0, 0, 0, 0.25);
    }

    @media (max-width: 1000px) {
        dialog {
            padding: 3.2rem 1.2rem 1.8rem 1.2rem;
        }
    }
</style>