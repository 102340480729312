<script lang="ts">
    import {slide} from 'svelte/transition';
    import {type Toast, type ToastManager, ToastType} from '$lib/classes/stores/toast-manager';
    import IconClose from '$lib/components/assets/IconClose.svelte';
    import SvgIcon from '$lib/components/assets/SvgIcon.svelte';
    import {svgContents} from '$lib/components/assets/svg-contents';

    export let toastManager: ToastManager

    const toastColors: Record<ToastType, string> = {
        [ToastType.Info]: 'var(--color-Verb)',
        [ToastType.Success]: 'var(--color-success)',
        [ToastType.Error]: 'var(--color-error)',
    }

    const toastIconNames: Record<ToastType, keyof typeof svgContents> = {
        [ToastType.Info]: 'info',
        [ToastType.Success]: 'success',
        [ToastType.Error]: 'error',
    }

    function handleRemove(toast: Toast) {
        toastManager.removeToast(toast)
    }
</script>

<div id="toasts">
    {#each $toastManager as toast (toast)}
        <div class="toast {toast.type.toLowerCase()}"
             class:auto-remove={toast.autoRemove}
             transition:slide
             style:--toast-color={toastColors[toast.type]}
        >
            <SvgIcon name={toastIconNames[toast.type]}
                     fill="var(--toast-color)"
                     viewBox="0 0 2048 2048"
                     width="24"
                     height="24"
            />
            <div class="toast-html">
                {@html toast.message}
            </div>
            <IconClose
                    width="20"
                    height="20"
                    top=".4rem"
                    right=".4rem"
                    fill="var(--toast-color)"
                    on:click={() => handleRemove(toast)}
            />
            {#if toast.link}
                <a href={toast.link.url}
                   on:click={() => handleRemove(toast)}
                >
                    {toast.link.text}
                </a>
            {/if}
            {#if toast.autoRemove}
                <div class="toast-progress"
                     on:animationend={() => handleRemove(toast)}
                >
                </div>
            {/if}
        </div>
    {/each}
</div>

<style>
    #toasts {
        position: fixed;
        top: 1rem;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 73ch;
        display: grid;
        justify-items: center;
        z-index: var(--z-index-toasts);
    }

    .toast {
        position: relative;
        max-width: 90%;
        display: grid;
        grid-template-columns: auto 1fr auto;
        align-items: center;
        gap: .6rem;
        padding: .8rem 2rem .8rem .8rem;
        background: var(--bg);
        border: 2px solid var(--toast-color);
        border-left-width: 1rem;
        border-radius: var(--border-radius);
        font-size: .95rem;
        font-weight: 520;
        box-shadow: var(--shadow-toast);
        overflow: hidden;
    }

    .toast-html :global(p) {
        margin: 0;
    }

    .toast-html :global(p + p) {
        margin-top: 1rem;
    }

    .toast-progress {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        width: 100%;
        height: 2px;
        background: var(--toast-color);
        animation: progress linear 2s forwards;
        animation-delay: 200ms;
    }

    @keyframes progress {
        100% {
            transform: translateX(0);
        }
    }

    .toast + .toast {
        margin-top: var(--space-sm);
    }

    a {
        padding: .25rem .3rem;
        border: 2px solid var(--toast-color);
        border-radius: var(--border-radius);
        background: var(--toast-color);
        color: var(--text-on-color);
        font-weight: var(--font-emph);
        font-size: .95em;
        white-space: nowrap;
    }
</style>