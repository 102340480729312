import {loadWordsSentences} from '$lib/utils/loadWordsSentences';
import {allWords, setAllWords} from '$lib/data/allWords';
import {wordIdToWord, initWordIdToWord} from '$lib/data/wordIdToWord';
import {filterOptions, initFilterOptions} from '$lib/data/filterOptions';
import {wordIdToSentences, initWordIdToSentences} from '$lib/data/wordIdToSentences';
import {sentenceIdToSentence, initSentenceIdToSentence} from '$lib/data/sentenceIdToSentence';
import {wordIdChanges, loadWordIdChanges} from '$lib/data/word-id-changes';
import {firebaseApp as FirebaseApp} from '$lib/classes/services/firebase.js';
import {Functions} from '$lib/classes/services/functions';
import {Database} from '$lib/classes/services/database';
import {Auth} from '$lib/classes/services/auth.js';
import type {User} from 'firebase/auth';
import {VerbConjugations} from '$lib/data/verb-conjugations';
import {getDisplayWords} from '$lib/stores/displayWords.js';
import {ReviewCount} from '$lib/classes/stores/review-count';
import {getFreeLimitReached} from '$lib/classes/stores/get-free-limit-reached';
import {ProVersion} from '$lib/classes/stores/pro-version';
import {Subscription} from '$lib/classes/stores/subscription';
import {PaymentService} from '$lib/classes/services/payment-service.js';
import {timer} from '$lib/utils/timer.js';
import {logInProduction} from '$lib/utils/logInProduction.js';
import {browser, version} from '$app/environment';
import * as Sentry from '@sentry/sveltekit';
import {getUserWordsMap} from '$lib/classes/stores/get-user-words-map';
import {Interval as interval} from '$lib/utils/userWords/interval';
import {getUserWordsToLearn} from '$lib/classes/stores/get-user-words-to-learn';
import {UserSettings} from '$lib/classes/stores/user-settings';
import {RemoteChanges} from '$lib/classes/stores/remote-changes';
import {UserWordConverter} from '$lib/classes/data/user-word-converter';
import {compressor} from '$lib/classes/services/compressor';
import {WordConverter} from '$lib/classes/data/word-converter';
import {SentenceConverter} from '$lib/classes/data/sentence-converter';
import {LoggedIn} from '$lib/classes/stores/logged-in';
import {getFilterSettings} from '$lib/stores/filterSettings';
import {getFilterSettingsFiltered} from '$lib/stores/filterSettings_filtered';
import {getCurrentWord} from '$lib/stores/currentWord';
import {getDevice} from '$lib/stores/ui/device';
import {pageWidth as PageWidth} from '$lib/stores/ui/pageWidth';
import {ToastManager} from '$lib/classes/stores/toast-manager';
import {getAccessLevel} from '$lib/classes/stores/access-level';
import {UserActionProcessor} from '$lib/classes/services/user-action-processor';
import {get} from 'svelte/store';
import {getIsPayingCustomer} from '$lib/classes/stores/get-is-paying-customer';
import {TierManager} from '$lib/classes/state/tier-manager.svelte';

export const prerender = true
export const ssr = false

export async function load({url}) {
    // TODO wrap everything in (app) layout group and place an +error.svelte page outside
    //  so that errors happing here in layout.js will lead to that page
    // NOTE errors should be catched in setup and a suitable message can be displayed in the layout with `{#await} ... {:then} ... {:catch}`?
    // QUESTION should be fine for setup, but what about onNewUser, onLogin, onLogout errors?
    // these should also be propagated to setup error since called as part of auth.init()..?!?

    logInProduction('------------')
    logInProduction('version ' + version)

    if (browser && url.searchParams.has('ref')) {
        const refCode = url.searchParams.get('ref')!
        localStorage.setItem('ref', refCode)
    }

    // data
    // QUESTION better classes?
    // NOTE with Svelte 5 Data class possible with derived fields and everything encapsulated in one class
    // let allWords = []
    // let wordIdToWord = {}
    // let filterOptions = {}
    // let allSentences = []
    // let wordIdToSentences = {}
    // let sentenceIdToSentence = {}
    let verbConjugations = new VerbConjugations()

    // converter
    const wordConverter = new WordConverter()
    const sentenceConverter = new SentenceConverter()
    const userWordConverter = new UserWordConverter()

    // services
    const firebaseApp = FirebaseApp // no class needed
    const database = new Database(firebaseApp, userWordConverter)
    const functions = new Functions(firebaseApp)

    const auth = new Auth(firebaseApp, functions, onNewUser, onLogin, onLogout)

    // stores
    const loggedIn = new LoggedIn()
    const remoteChanges = new RemoteChanges(onLogout)
    const pageWidth = PageWidth
    const device = getDevice(PageWidth)
    // state
    const toastManager = new ToastManager()

    const userSettings = new UserSettings(database)
    // NOTE Word/scala types needed for ts !!
    const userWordsMap = getUserWordsMap(database, userWordConverter, remoteChanges, wordIdToWord, wordIdToSentences, wordIdChanges, interval)
    const userWordsToLearn = getUserWordsToLearn(userWordsMap, allWords)
    const proVersion = new ProVersion(database)
    const subscription = new Subscription(database, functions)
    const accessLevel = getAccessLevel(loggedIn, proVersion, subscription)
    const isPayingCustomer = getIsPayingCustomer(subscription, proVersion)
    const paymentService = new PaymentService()
    const tierManager = new TierManager(paymentService, toastManager)
    const reviewCount = new ReviewCount(database)
    const freeLimitReached = getFreeLimitReached(loggedIn, proVersion, subscription, reviewCount)

    const userActionProcessor = new UserActionProcessor(accessLevel, toastManager, userWordsMap)

    // stores - Browse //TODO move to browse load..?!
    const currentWord = getCurrentWord() //NOTE should never be null --> remove $currentWord!
    const displayWords = getDisplayWords()
    const filterSettings = getFilterSettings()
    const filterSettingsFiltered = getFilterSettingsFiltered(filterSettings)

    async function onNewUser() {
        Sentry.setTag('new_user', 'true');
        userWordsMap.activateSavingPersistedUserWords()
    }

    async function onLogin(user: User) {
        const timer_onLogin = timer('onLogin')
        const uid = user.uid

        try {
            Sentry.setUser({id: uid})
        } catch (error) {
            console.error('ERROR Sentry.setUser', error)
        }

        try {
            // NOTE any of these failing should block app
            // TODO check that thrown error in each function will be catched
            // noinspection ES6MissingAwait
            const promises = [
                userWordsMap.init(uid),
                userSettings.init(user),
                reviewCount.init(uid),
                proVersion.init(user),
                subscription.connect(uid),
                verbConjugations.init(),
            ]
            await Promise.all(promises)

            loggedIn.change(true)

        } catch (error) {
            throw new Error(`ERROR onLogin`, {cause: error})
        }

        // NOTE shouldn't block app
        // noinspection ES6MissingAwait
        paymentService.authenticate(get(subscription))

        await userWordsMap.loadAndSavePersistedUserWords(uid)

        try {
            // @ts-ignore
            window.Tawk_API?.setAttributes({jobTitle: user.uid}, (error: unknown) => {
                if (error === undefined) return
                console.error('ERROR tawk.setAttributes', error)
            });
        } catch (error) {
            // Who cares.
        }

        timer_onLogin()
    }

    async function onLogout() {
        // NOTE route protection handled in +layout.svelte
        //  (calling goto in load functions triggered parent load rerun)

        loggedIn.change(false)
        // TODO change to ONE wording close/clear/terminate/disconnect
        try {
            userWordsMap.close()
            userSettings.clear()
            reviewCount.terminate()
            proVersion.close()
            subscription.disconnect()
            await paymentService.revokeAuthorization()
        } catch (error) {
            console.error('ERROR onLogout', error)
        }
    }

    async function setup() {
        if (!browser) return

        const timer_setup = timer('setup')
        let step = ''
        try {
            // NOTE needs to run concurrently because userWordsMap needs data to validate saved userWords
            //  Errors loading data or initializing auth will be catched in AwaitSetup Comp (including errors of first run of onLogin)
            //  What happens if error in onLogin when opening page being logged out? -> Not thrown..? But still seems to block -> not logged in
            step = 'load data'
            const timer_data = timer('data')

            const [words, sentences] = await loadWordsSentences(compressor, wordConverter, sentenceConverter)

            setAllWords(words)
            initWordIdToWord(words)
            initFilterOptions(words)
            displayWords.init(words)

            initWordIdToSentences(sentences)
            initSentenceIdToSentence(sentences)

            // await verbConjugations.init() //when not only needed when logged in
            timer_data()

            step = 'initialize wordIdChanges'
            await loadWordIdChanges()

            step = 'initialize Paddle' //already done here for Profitwell Retain
            const timer_paddle = timer('paddle')
            await paymentService.init()
            timer_paddle()

            step = 'initialize auth'
            const timer_auth = timer('auth')
            await auth.init()
            timer_auth()

        } catch (error) {
            throw new Error(`ERROR in ${step}`, {cause: error})
        }
        timer_setup()
    }

    return {
        setup: setup(),
        database: database,
        allWords: allWords,
        wordIdToWord: wordIdToWord,
        filterOptions: filterOptions,
        wordIdToSentences: wordIdToSentences,
        sentenceIdToSentence: sentenceIdToSentence,
        functions: functions,
        auth: auth,
        loggedIn: loggedIn,
        remoteChanges: remoteChanges,
        device: device,
        pageWidth: pageWidth,
        userWordsMap: userWordsMap,
        userWordsToLearn: userWordsToLearn,
        currentWord: currentWord,
        displayWords: displayWords,
        filterSettings: filterSettings,
        filterSettingsFiltered: filterSettingsFiltered,
        verbConjugations: verbConjugations,
        reviewCount: reviewCount,
        freeLimitReached: freeLimitReached,
        userSettings: userSettings,
        proVersion: proVersion,
        subscription: subscription,
        isPayingCustomer: isPayingCustomer,
        paymentService: paymentService,
        tierManager: tierManager,
        toastManager: toastManager,
        userActionProcessor: userActionProcessor,
    }
}
