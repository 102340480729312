import type {Tier} from '$lib/classes/_types/tier';
import {type ToastManager} from '$lib/classes/stores/toast-manager';
import type {PaymentService} from '$lib/classes/services/payment-service';

const tiers: Tier[] = [
    {
        planName: 'Bronze',
        month: '1 - 3',
        quantity: 1,
        priceId: import.meta.env.VITE_PADDLE_PRO_BRONZE_PRICE_ID,
        price: null,
        lessPercentThanInitialPrice: null,
    },
    {
        planName: 'Silver',
        month: '4 - 6',
        quantity: 1,
        priceId: import.meta.env.VITE_PADDLE_PRO_SILVER_PRICE_ID,
        price: null,
        lessPercentThanInitialPrice: null,
    },
    {
        planName: 'Gold',
        month: '7 - 12',
        quantity: 1,
        priceId: import.meta.env.VITE_PADDLE_PRO_GOLD_PRICE_ID,
        price: null,
        lessPercentThanInitialPrice: null,
    },
    {
        planName: 'Platinum',
        month: '12+',
        quantity: 1,
        priceId: import.meta.env.VITE_PADDLE_PRO_PLATINUM_PRICE_ID,
        price: null,
        lessPercentThanInitialPrice: null,
    }
]


export class TierManager {
    private _tiers: Tier[] = $state(tiers)

    get tiers() {
        return this._tiers
    }

    constructor(
        private readonly paymentService: PaymentService,
        private readonly toastManager: ToastManager
    ) {}

    async loadPrices() {
        try {
            this._tiers = await this.paymentService.getPrices(this._tiers)
        } catch (error) {
            console.error('ERROR loading prices', error)
            this.toastManager.addErrorLoadingPricesToast()
        }
    }
}