import type {Database} from "$lib/classes/services/database";
import {writable} from "svelte/store";
import type {User} from "firebase/auth";
import Sqids from "sqids";

export type ReferralInfo = {
    by: string | null,
    code: string,
}

export type UserSettingsDoc = {
    autoAddSentence: boolean,
    selectedColor: string,
    colorCodeNouns: boolean,
    nounColors: {
        maskulinum: string,
        femininum: string,
        neutrum: string
    },
    showSingularPluralOfNouns: boolean,
    showVerbConjugation: boolean,
    wordsPerSession: {
        limit: boolean,
        count: number,
    },
    randomizeWordOrder: boolean,
    autofocusInput: boolean,
    addWordsCounts: number[],
    reminder: {
        timezone: string,
        emailHour: number,
        sendEmail: boolean,
    },
    referral?: ReferralInfo
}

const initialValue: UserSettingsDoc = {
    autoAddSentence: true,
    selectedColor: 'var(--color-Verb)',
    colorCodeNouns: false,
    nounColors: {
        maskulinum: '#103e96',
        femininum: '#852276',
        neutrum: '#1e6c1e'
    },
    showSingularPluralOfNouns: false,
    showVerbConjugation: false,
    wordsPerSession: {
        limit: false,
        count: 50,
    },
    randomizeWordOrder: false,
    autofocusInput: false,
    addWordsCounts: [10, 25, 50],
    reminder: {
        timezone: '',
        emailHour: 8,
        sendEmail: false,
    }
}

export class UserSettings {
    private readonly store = writable<UserSettingsDoc>({...initialValue})
    readonly subscribe = this.store.subscribe
    readonly set = this.store.set

    private uid: string | null = null
    private unsubscribeUserSettingsDoc: () => void = () => {}

    constructor(
        private readonly database: Database,
    ) {}

    private getInitialUserSettingsDoc(user: User) {
        // NOTE User is missing createdAt, but it's present on instance UserImpl (internal)
        //  use if present since it's more precise (creationTime is missing milliseconds)
        // @ts-ignore
        const createdAt: string = user.metadata.createdAt
        if (!createdAt) console.error('ERROR User.metadata.createdAt missing >> falling back to .creationTime')
        const creationTime = user.metadata.creationTime
        const creationTimestamp = createdAt ? +createdAt : creationTime ? new Date(creationTime).getTime() : null
        if (!creationTimestamp) return {}
        try {
            const sqids = new Sqids({
                alphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
            })
            const initialDoc = {
                referral: {
                    code: sqids.encode([creationTimestamp]),
                    by: localStorage.getItem('ref') ?? null,
                }
            }
            localStorage.removeItem('ref')
            return initialDoc
        } catch (error) {
            console.error('ERROR creating initialSettingsDoc', error)
            return {}
        }
    }

    async init(user: User): Promise<void> {
        const startInit = Date.now()
        const uid = user.uid
        this.uid = uid
        let initialCall = true
        return new Promise(async (resolve, reject) => {
            try {
                const startGetDoc = Date.now()
                const userSettingsDoc = await this.database.getUserSettingsDoc(uid)
                const getDoc = Date.now() - startGetDoc
                const startSetDoc = Date.now()
                if (userSettingsDoc === null) {
                    await this.database.setUserSettingsDoc(uid, this.getInitialUserSettingsDoc(user))
                }
                const setDoc = Date.now() - startSetDoc

                //NOTE stopped saving lastLogin since it's available on User.metadata.lastRefreshTime

                const startSubscribe = Date.now()
                this.unsubscribeUserSettingsDoc = await this.database.subscribeUserSettingsDoc(uid, (userSettingsDoc: UserSettingsDoc | null) => {
                    if (userSettingsDoc) {
                        this.store.update(value => {
                            return {...value, ...userSettingsDoc}
                        })
                    }
                    const subscribe = Date.now() - startSubscribe
                    if (initialCall) {
                        console.log('userSettings.init:', Date.now() - startInit, ' - getDoc:', getDoc, 'setDoc:', setDoc, 'subscribe:', subscribe)
                        initialCall = false
                    }
                    resolve()
                })

            } catch (error) {
                reject(new Error('ERROR userSettings.init()', {cause: error}))
            }
        })
    }

    async changeSetting<K extends keyof UserSettingsDoc>(key: K, value: UserSettingsDoc[K]) {
        try {
            let updateDoc: Partial<UserSettingsDoc> = {
                [key]: value,
            }
            this.store.update(value => {
                return {...value, ...updateDoc}
            })
            if (this.uid) {
                await this.database.setUserSettingsDoc(this.uid, updateDoc)
            }
        } catch (error) {
            console.error('ERROR while changing userSetting', error)
        }
    }

    clear() {
        this.unsubscribeUserSettingsDoc()
        this.store.set({...initialValue})
        this.uid = null
    }
}